import { motion } from "framer-motion";
import { MyVariants } from "./MyVariants";

const BlogItem = ({ item }) => {
  return (
    <motion.article
      variants={MyVariants}
      whileHover="myWhileHover"
      className="blog__item"
    >
      <a href={item.link} className="content-wrapper">
        <div className="container">
          <h3>{item.title.rendered}</h3>
          <p>{item.excerpt.rendered}</p>
        </div>
      </a>
    </motion.article>
  );
};

export default BlogItem;
