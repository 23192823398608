import { useEffect } from "react"
import ReactGA from "react-ga"
import {useLocation} from "react-router-dom"

const TRACKER_ID = "UA-143606380-1"

export const useTracker = () => {
    let location = useLocation()

    useEffect(() => {
        if( !window.location.href.includes("localhost") ) {
            ReactGA.initialize(TRACKER_ID)
            ReactGA.pageview(location.pathname + location.search)
        }

    }, [location])
}