const Envelop = () => {
  return (
    <svg
      className="envelop"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.75 0H2.25C1.00734 0 0 1.00734 0 2.25V15.75C0 16.9927 1.00734 18 2.25 18H21.75C22.9927 18 24 16.9927 24 15.75V2.25C24 1.00734 22.9927 0 21.75 0ZM21.75 2.25V4.16273C20.699 5.01862 19.0234 6.3495 15.4412 9.15445C14.6518 9.77541 13.0881 11.2672 12 11.2498C10.9121 11.2674 9.34786 9.77517 8.55877 9.15445C4.97719 6.34992 3.30117 5.01877 2.25 4.16273V2.25H21.75ZM2.25 15.75V7.04991C3.32409 7.90542 4.8473 9.10594 7.16897 10.9239C8.19352 11.7304 9.98775 13.5108 12 13.5C14.0024 13.5108 15.7739 11.7563 16.8306 10.9243C19.1522 9.10636 20.6759 7.90552 21.75 7.04995V15.75H2.25Z"
        fill="#020208"
      />
    </svg>
  );
};

export default Envelop;