const ProjetItem = ({ item, children }) => {
  return (
    <article className="projet__item">
      <div className="container">
        <div className="content__wrapper">
          <div className="content">{children}</div>
          <a href={item.url}>{item.urlName}</a>
        </div>
      </div>
    </article>
  );
};

export default ProjetItem;
