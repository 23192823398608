import RealisationDetails from "../../pages/RealisationDetails";

const Pnrg = () => {
  return (
    <>
      <RealisationDetails
        preview="pnrg.jpg"
        siteUrl="https://parcnaturel-guyane.fr/"
      >
        <h2>Menu PNRG</h2>

        <p>
          Naviguer sur le site du Parc Naturel Régional de la Guyane, avec ce
          menu développé spécialement pour ce site.
        </p>

        <ul>
          <li>Designer : Elodie Alexander</li>
          <li>Studio : Focus l'Art communique</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Pnrg;
