import { motion } from "framer-motion";
import About from "../components/About";
import Blog from "../components/Blog";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Hero";
import { MyVariants } from "../components/MyVariants";
import Projet from "../components/Projet";
import Realisation from "../components/Realisation";
import Service from "../components/Service";

const Home = () => {
  return (
    <>
      <Header />
      <motion.main className="home" variants={MyVariants} exit="myExit">
        <Hero />
        <Realisation />
        <About />
        <Service />
        <Blog />
        <Projet />
        <CallToAction />
      </motion.main>
      <Footer />
    </>
  );
};

export default Home;
