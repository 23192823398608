import RealisationDetails from "../../pages/RealisationDetails";

const Emak = () => {
  return (
    <>
      <RealisationDetails
        preview="Écomusée-municipal-dApprouague-Kaw-Régina-Guyane.jpg"
        siteUrl="https://www.emak-regina.fr/"
      >
        <h2>Ecomusée municipal d’Approuague Kaw</h2>

        <p>
          Ma mission : Dans le cadre d’un partenariat avec AMAZONE STUDIO,
          développement et intégration du template WordPress.
        </p>

        <ul>
          <li>Designer : Jérémie LAHANQUE</li>
          <li>Studio : Côté Cube</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Emak;
