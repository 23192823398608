import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CallToAction from "../components/CallToAction";
import { motion } from "framer-motion";
import { MyVariants } from "../components/MyVariants";

const WPTheme = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  return (
    <>
      <Header />
      <motion.div variants={MyVariants} exit="myExit" className="wp-theme">
        <main>
          {/* <section className="title">
            <div className="page container">
              <YouCanBookMe>Première consultation gratuite</YouCanBookMe>
            </div>
          </section> */}

          <section className="filter">
            <div className="page container">
              <h2>Développement de Thème WordPress</h2>
              <h3>À qui s’adresse cette offre ?</h3>
              <ul>
                <li>Les designers</li>
                <li>
                  Les PME (agences de communication, de marketing, de design)
                </li>
              </ul>
            </div>
          </section>

          <section className="why-me">
            <div className="page container">
              <h3>Pourquoi me confier le développement de vos designs ?</h3>

              <p>
                Soyons honnête : designer et développer son site seul est tout à
                fait possible, c’est même ce qui se fait régulièrement.
              </p>
              <p>
                Le problème lorsqu’on incarne les 2 rôles, c’est qu’il est
                difficile de vraiment s’épanouir. Souvent tu seras limité dans
                le temps ce qui va couper court à ton processus créatif.
              </p>
              <p>Le résultat sera :</p>
              <ul>
                <li>un manque de préparation,</li>
                <li>une période d’incubation trop courte,</li>
                <li>un déclic ne se faisant pas vraiment,</li>
                <li>peu de vérifications pour savoir si ton idée marche…</li>
              </ul>
              <p>
                Ceci est bien entendu le “scénario catastrophe”. Même si ces
                phases sont liées ce n’est pas pour autant qu’elles vont toutes
                échouées ! Mais il faut bien l‘admettre ta créativité est
                freinée voir limitée.
              </p>
              <p>
                Un autre problème, et celui-ci est gênant : c’est celui de
                devoir retoucher ton design parce que tu n’arrives pas à coder
                certaines parties, et ce dernier ne marche pas comme prévu 🙁
              </p>
              <p>
                Dans tous les cas, le résultat final est grandement impacté.
              </p>

              <p className="quote">J’aurais pu faire mieux</p>

              <p>
                Il m’arrive de me le dire à la fin d’un projet. C’est plutôt
                positif, c’est la volonté de vouloir apporter le plus de valeur
                que possible à mes clients.
              </p>
              <p>
                Toutefois, quand cela arrive souvent c’est le signe d’une
                frustration…
              </p>
              <p>Avec le temps, je suis parvenu à la conclusion suivante :</p>
              <p>
                Designer et développeur partagent les mêmes buts, celui de créer
                une expérience enrichissante pour les utilisateurs, quelque
                chose de suffisamment fort pour que ces derniers reviennent voir ton
                site (ou ton application) et aillent jusqu’à partager leur «
                trouvaille » à leurs amis 🙂
              </p>
              <p>
                Mais pour aboutir à d’aussi beaux projets, il faudrait séparer
                les tâches de design et de développement.
              </p>
              <p>
                Les 2 spécialités ont un vaste panel de compétences à maîtriser.
              </p>
              <p>
                De mon point de vue le designer se spécialise dans ce qui fait
                sens pour l’utilisateur, tandis que le développeur se spécialise
                dans ce qui fait sens pour l’ordinateur.
              </p>
              <p>
                Et je suis persuadé qu’un projet réussi est le résultat d’une
                collaboration harmonieuse.
              </p>
              <img src={process.env.PUBLIC_URL + "/img/woah.gif"} alt="" />
              <p>
                Si comme moi tu souhaites t’éclater dans le web (design) je te
                propose l’organisation suivante :
              </p>

              <p>
                <b>tu design, je code !</b>
              </p>
            </div>
          </section>

          <section className="the-solution">
            <div className="page container">
              <h3>Ma solution</h3>
              <p>
                <b>
                  Transformer tes designs en un thème WordPress de haute qualité
                  et pleinement fonctionnel.
                </b>
              </p>
              <p>
                Et voici ce que tu obtiendras si nous travaillons ensemble :
              </p>
              <ul>
                <li>un thème responsive,</li>
                <li>qui suit les standards du web,</li>
                <li>qui a un très bon niveau d’accessibilité,</li>
                <li>
                  qui est optimisé afin d’améliorer les temps de chargement des
                  pages.
                </li>
              </ul>
              <p> De plus je peux : </p>
              <ul>
                <li>écrire du JavaScript personnalisé ;</li>
                <li>créer des animations CSS personnalisées ;</li>
                <li>personnaliser le back-office.</li>
              </ul>
            </div>
          </section>

          <section className="option">
            <div className="page container">
              <h3>Les 2 options</h3>
              <motion.div className="row">
                <motion.div
                  variants={MyVariants}
                  whileHover="myWhileHover"
                  className="column"
                >
                  <h4>Du design à WordPress</h4>
                  <p>
                    Envoie tes mockups au format (Figma, Sketch, Adobe XD) et je
                    les convertis en thème WordPress.
                  </p>
                </motion.div>
                <motion.div
                  variants={MyVariants}
                  whileHover="myWhileHover"
                  className="column"
                >
                  <h4>Ou, de l’HTML à WordPress</h4>
                  <p>Si tu possèdes déjà des HTML parce que tu as :</p>
                  <ul>
                    <li>un site en HTML</li>
                    <li>acheté un thème HTML</li>
                  </ul>
                  <p>
                    Fais le moi savoir et je les convertirai en thème WordPress.
                  </p>
                </motion.div>
              </motion.div>
            </div>
          </section>
        </main>

        <section className="step">
          <div className="page container">
            <h3>Comment on procède ?</h3>
            <p>
              Je souhaite une collaboration où nous serons tous deux acteurs !
            </p>
            <h4>Étape 1</h4>
            <p>
              Elle consistera à prendre un rendez-vous pour échanger sur ton
              projet, j’ai besoin de savoir ce qui te tient à cœur. À ce moment,
              je pourrai répondre à tes dernières interrogations et te donner
              plus de précisions sur notre collaboration.
            </p>
            <p>
              Aussi en fonction de ton besoin je te dirai ce qu’il me faut pour
              commencer. Par exemple : tes fichiers de design (PNG, JPEG, PDF,
              SKETCH, ADOBE XD, etc) ou tes HTML.
            </p>
            <p>
              De plus, si nécessaire, il faudra que tu m’expliques comment
              marche ton design : les animations, les effets, etc.
            </p>
            <p>
              Ce n’est qu’à partir de ce moment que je pourrais évaluer le temps
              nécessaire à la réalisation.
            </p>
            <h4>Étape 2</h4>
            <p>
              Une fois tous les détails éclaircis, on peut procéder à l’envoi
              des fichiers, afin que je puisse démarrer le développement.
            </p>
            <h4>Étape 3 </h4>
            <p>
              J’ai nommé “La preview”, ce sera le moment pour nous d’apporter
              les touches finales, de changer ce qui est nécessaire ou
              simplement de valider le résultat.
            </p>
            <h4>Étape 4</h4>
            <p>
              Je t’enverrai un lien de paiement, qui te débloquera le
              téléchargement de tes fichiers. Et si besoin, je t’apporterai
              l’aide nécessaire pour installer ton thème.
            </p>
          </div>
        </section>
      </motion.div>

      <CallToAction />
      <Footer />
    </>
  );
};

export default WPTheme;
