import SocialLink from "../components/SocialLink";
import Button from "../components/Button";

const NotFound = () => {
  return (
    <main className="not-found">
      <div>
        <div className="msg">404</div>
        <p>Page non trouvée</p>

        <Button url="/" color="orange">
          <span>←</span> Revenir à la page d'accueil
        </Button>

        <SocialLink />
      </div>
    </main>
  );
};

export default NotFound;
