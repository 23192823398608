import Button from "./Button";

const ServiceItem = ({ children, readMoreUrl }) => {
  return (
    <div className="service__item">
      <div className="container">
        <div className="content-wrapper">
          <div className="content">{children}</div>

          {readMoreUrl && (
            <Button url={readMoreUrl} color="blue">
              En savoir plus
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceItem;
