import MailToAFDM from "./MailToAFDM";
import RealisationGalery from "./RealisationGalery";

const Realisation = () => {
  return (
    <section className="realisation" id="realisation">
      <div className="container">
        <h2>Réalisations</h2>
        <h3>Une sélection de projets sur lesquels j’ai travaillé.</h3>

        <RealisationGalery />

        <div className="more-realisation">
          Pour en voir plus, <MailToAFDM>contactez moi par email.</MailToAFDM>
        </div>
      </div>
    </section>
  );
};

export default Realisation;
