import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { MyVariants } from "./MyVariants";

const RealisationItem = ({ src, detailsLink, name = "" }) => {
  const MotionLink = motion(Link);

  return (
    <MotionLink
      to={detailsLink}
      className={`realisation__item ${name}`}
      variants={MyVariants}
      whileHover="myWhileHover"
      exit="myExit2"
    >
      <img src={process.env.PUBLIC_URL + src} alt="" />
    </MotionLink>
  );
};

export default RealisationItem;
