import RealisationDetails from "../../pages/RealisationDetails";

const Crpv = () => {
  return (
    <>
      <RealisationDetails
        preview="CRPV-Guyane-Centre-de-Ressources-Politique-de-la-Ville-de-Guyane.jpg"
        siteUrl="https://www.crpv-guyane.org/"
      >
        <h2>Le Centre de Ressources Politique de la Ville</h2>

        <p>
          Ma mission : Dans le cadre d’un partenariat avec AMAZONE STUDIO,
          développement et intégration du template WordPress.
        </p>

        <ul>
          <li>Designer : Jérémie LAHANQUE</li>
          <li>Studio : Côté Cube</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Crpv;
