const LinkedIn = () => {
  return (
    <svg
      className="linkedin"
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.498 17.7316V27.5004H23.6993V18.9004C23.6993 17.8378 23.6948 16.4993 23.2705 15.428C23.0526 14.8779 22.7156 14.3728 22.1936 14.0087C21.6706 13.6439 21.0045 13.4504 20.1812 13.4504C19.3616 13.4504 18.6793 13.6105 18.1219 13.9192C17.5615 14.2294 17.1572 14.6732 16.8712 15.1917C16.3123 16.2052 16.2005 17.5162 16.2005 18.7441V27.5004H11.4018V9.8066H15.9693V11.8566V12.3566H16.4693H16.5505H16.852L16.9928 12.0899C17.6854 10.7773 19.4129 9.33785 22.043 9.33785C24.8572 9.33785 26.4109 10.2536 27.3005 11.6667C28.2236 13.1329 28.498 15.2299 28.498 17.7316ZM6.76554 27.5004H1.96055V9.8066H6.76554V27.5004ZM4.35992 6.2566C2.78593 6.2566 1.49805 4.94911 1.49805 3.36285C1.49805 2.60383 1.79956 1.87591 2.33627 1.3392C2.87298 0.802495 3.6009 0.500977 4.35992 0.500977C5.11894 0.500977 5.84687 0.802495 6.38357 1.3392C6.92028 1.87591 7.22179 2.60383 7.22179 3.36285C7.22179 4.94905 5.93335 6.2566 4.35992 6.2566Z"
        fill="#020208"
        stroke="black"
      />
    </svg>
  );
};

export default LinkedIn;
