import MailToAFDM from "./MailToAFDM";
import Envelop from "./assets/Envelop";

const About = () => {
  return (
    <section className="about" id="about">
      <div className="container">
        <h2>Qui suis-je ?</h2>
        <h3>Développeur front-end & WordPress</h3>
        <div className="about__wrapper">
          <article>
            <div className="content">
              <p>Salut, je suis Alix.</p>
              <p>
                Je vous aide à transformer une idée de design en une
                expérience fonctionnelle et agréable, en convertissant vos
                designs en un site ou une application :
              </p>

              <ul>
                <li>- responsive,</li>
                <li>- accessible,</li>
                <li>- riche en fonctionnalités.</li>
              </ul>

              <p>
                Si vous consultez cette page, c’est que vous êtes probablement à
                la recherche d’un partenaire pour vous construire un thème
                WordPress sur-mesure ou le front de votre prochaine web app.
              </p>

              <p>
                Vous avez un design, mais il vous manque un artisan du web qui
                soit capable de l’intégrer et développer ses fonctionnalités ?
                C’est exactement ce que je fais !
              </p>

              <p>Contactez-moi à l'adresse suivante :</p>
            </div>
            <div className="contact">
              <MailToAFDM>
                <Envelop />
                <span>bonjour@alixfdm.fr</span>
              </MailToAFDM>
            </div>
          </article>
          <img src={process.env.PUBLIC_URL + "/img/alixfdm.png"} alt="" />
        </div>
      </div>
    </section>
  );
};

export default About;
