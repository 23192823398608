import { useEffect, useState } from "react";

export const useFetchData = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isPending, setIsPending] = useState(false);

  /* 
    - Create an AbortController instance
    - That instance has a signal property
    - Pass the signal as a fetch option for signal
    - Call the AbortController's abort property to cancel all fetches that use that signal.
  */

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        const resp = await fetch(url, { signal: abortController.signal });

        setIsPending(true);

        if (resp.ok) {
          const json = await resp.json();

          setData(json);
          setIsPending(false);
          setError(null);
        }
      } catch (err) {
        if (err.name === "AbordError") {
          console.log("fetch canceled");
        } else {
          setError("Fetch problem : " + err.message);
        }
      }
    };

    if (url) {
      fetchData();
    }

    return () => abortController.abort(); // component will die
  }, [url]);

  return { data, isPending, error };
};
