import { HashLink } from "react-router-hash-link";

const Logo = ({ url = "/" }) => {
  return (
    <div className="logo">
      <HashLink to={url}> Alix FDM</HashLink>
    </div>
  );
};

export default Logo;
