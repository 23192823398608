import { motion } from "framer-motion";
import { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import MailToAFDM from "../components/MailToAFDM";
import { MyVariants } from "../components/MyVariants";

const LegalMention = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      <motion.main
        className="legal-mention page container"
        variants={MyVariants}
        exit="myExit"
      >
        <h2>Mentions légales</h2>
        <h3>Édition</h3>
        <p>
          Ce site est édité par Alix FRANÇOIS DIT MARÉCHAUX – n° de SIRET : 890
          745 607 00014 dont l'adresse est le 54 rue des Ixoras lotissement
          Cogneau Larivot, Matoury 97351.
        </p>

        <ul>
          <li>
            Email de contact : <MailToAFDM>bonjour@alixfdm.fr</MailToAFDM>
          </li>
          <li>N° de téléphone : +33 6 30 64 95 00</li>
        </ul>

        <h3>Hébergement</h3>

        <p>
          <a href="//www.ovh.com/fr/">OVH SAS</a>
        </p>

        <p>
          2 rue Kellermann <br />
          59100 Roubaix – France.
        </p>

        <p>
          <a href="//www.ovh.com/fr/">www.ovh.com/fr/</a>
        </p>

        <h3>Cookies</h3>
        <p>
          Ce site peut collecter automatiquement des informations standards
          telles que tous types d’informations personnalisées qui permettent au
          site d’identifier ses visiteurs.
        </p>

        <p>
          Toutes les informations collectées indirectement ne seront utilisées
          que pour suivre le volume, le type et la configuration du trafic
          utilisant ce site, pour en développer la conception et l’agencement et
          à d’autres fins administratives et de planification et plus
          généralement pour améliorer le service que nous vous offrons.
        </p>

        <p>
          Vous avez la possibilité de visiter ce site en désactivant cette
          option dans les paramètres de votre navigateur.
        </p>

        <h3>Propriété intellectuelle</h3>
        <p>
          Toutes les marques, photographies, textes, commentaires,
          illustrations, images animées ou non, séquences vidéo, sons, ainsi que
          toutes les applications informatiques qui pourraient être utilisées
          pour faire fonctionner ce site et plus généralement tous les éléments
          reproduits ou utilisés sur ce site sont protégés par les lois en
          vigueur au titre de la propriété intellectuelle.
        </p>

        <p>
          Ils sont la propriété pleine et entière de l’éditeur ou de ses
          partenaires, sauf mentions particulières. Toute reproduction,
          représentation, utilisation ou adaptation, sous quelque forme que ce
          soit, de tout ou partie de ces éléments, y compris les applications
          informatiques, sans l’accord préalable et écrit de l’éditeur, sont
          strictement interdites. Le fait pour l’éditeur de ne pas engager de
          procédure dès la prise de connaissance de ces utilisations non
          autorisées ne vaut pas acceptation desdites utilisations et
          renonciation aux poursuites.
        </p>

        <p>
          Seule l’utilisation pour un usage privé dans un cercle de famille est
          autorisée et toute autre utilisation est constitutive de contrefaçon
          et/ou d’atteinte aux droits voisins, sanctionnées par Code de la
          propriété intellectuelle. La reprise de tout ou partie de ce contenu
          nécessite l’autorisation préalable de l’éditeur ou du titulaire des
          droits sur ce contenu.
        </p>
      </motion.main>
      <Footer />
    </>
  );
};

export default LegalMention;
