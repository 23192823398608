import { useHistory } from "react-router";

const Button = ({ children, url, color = "blue" }) => {
  const history = useHistory();

  const handleClick = () => {
    if (url) history.push(url);
  };

  const btnColor = {
    blue: "btn-blue",
    orange: "btn-orange",
    "filled-orange": "filled btn-orange",
  };

  return (
    <button
      type="button"
      className={`${btnColor[color]}`}
      onClick={handleClick}
    >
      {children}
    </button>
  );
};

export default Button;
