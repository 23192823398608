import "./sass/main.scss";

// Componnents
import { Route, Switch } from "react-router-dom";
import { Routes } from "./RouteConfig";
import { AnimatePresence } from "framer-motion";
import { useTracker } from "./components/hooks/useTracker";

// pro tips  : to have the animation on the route transition, AnimatePresence shoulbe wrapped tout this (below)

/* <Route
  render={({ location }) => (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch location={location} key={location.pathname}>
        <Route exact path="/" component={Gallery} />
        <Route exact path="/image/:id" component={SingleImage} />
      </Switch>
    </AnimatePresence>
  )}
/> */

function App() {
  useTracker()
  
  return (
    <>
      <Route
        render={({ location }) => (
          <AnimatePresence /* initial={false} */ exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              {Routes.map((route, index) => (
                <Route key={index} {...route} />
              ))}
            </Switch>
          </AnimatePresence>
        )}
      />
    </>
  );
}

export default App;
