import RealisationDetails from "../../pages/RealisationDetails";

const Kourou = () => {
  return (
    <>
      <RealisationDetails
        preview="Site-officiel-de-la-Ville-de-Kourou.jpg"
        siteUrl="https://www.ville-kourou.fr/"
      >
        <h2>La ville de Kourou</h2>

        <p>
          Ma mission : Dans le cadre d’un partenariat avec AMAZONE STUDIO,
          développement et intégration du template WordPress.
        </p>

        <ul>
          <li>Designer : Jérémie LAHANQUE</li>
          <li>Studio : Côté Cube</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Kourou;
