const Instagram = () => {
  return (
    <svg
      className="instagram"
      width="29"
      height="32"
      viewBox="0 0 29 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8645 8.81221C10.8895 8.81221 7.68321 12.0185 7.68321 15.9935C7.68321 19.9685 10.8895 23.1747 14.8645 23.1747C18.8395 23.1747 22.0457 19.9685 22.0457 15.9935C22.0457 12.0185 18.8395 8.81221 14.8645 8.81221ZM14.8645 20.6622C12.2957 20.6622 10.1957 18.5685 10.1957 15.9935C10.1957 13.4185 12.2895 11.3247 14.8645 11.3247C17.4395 11.3247 19.5332 13.4185 19.5332 15.9935C19.5332 18.5685 17.4332 20.6622 14.8645 20.6622ZM24.0145 8.51846C24.0145 9.44971 23.2645 10.1935 22.3395 10.1935C21.4082 10.1935 20.6645 9.44346 20.6645 8.51846C20.6645 7.59346 21.4145 6.84346 22.3395 6.84346C23.2645 6.84346 24.0145 7.59346 24.0145 8.51846ZM28.7707 10.2185C28.6645 7.97471 28.152 5.98721 26.5082 4.34971C24.8707 2.71221 22.8832 2.19971 20.6395 2.08721C18.327 1.95596 11.3957 1.95596 9.08321 2.08721C6.84571 2.19346 4.85821 2.70596 3.21446 4.34346C1.5707 5.98096 1.06445 7.96846 0.951953 10.2122C0.820703 12.5247 0.820703 19.456 0.951953 21.7685C1.0582 24.0122 1.5707 25.9997 3.21446 27.6372C4.85821 29.2747 6.83946 29.7872 9.08321 29.8997C11.3957 30.031 18.327 30.031 20.6395 29.8997C22.8832 29.7935 24.8707 29.281 26.5082 27.6372C28.1457 25.9997 28.6582 24.0122 28.7707 21.7685C28.902 19.456 28.902 12.531 28.7707 10.2185ZM25.7832 24.2497C25.2957 25.4747 24.352 26.4185 23.1207 26.9122C21.277 27.6435 16.902 27.4747 14.8645 27.4747C12.827 27.4747 8.44571 27.6372 6.60821 26.9122C5.38321 26.4247 4.43946 25.481 3.94571 24.2497C3.21446 22.406 3.38321 18.031 3.38321 15.9935C3.38321 13.956 3.22071 9.57471 3.94571 7.73721C4.43321 6.51221 5.37696 5.56846 6.60821 5.07471C8.45196 4.34346 12.827 4.51221 14.8645 4.51221C16.902 4.51221 21.2832 4.34971 23.1207 5.07471C24.3457 5.56221 25.2895 6.50596 25.7832 7.73721C26.5145 9.58096 26.3457 13.956 26.3457 15.9935C26.3457 18.031 26.5145 22.4122 25.7832 24.2497Z"
        fill="#020208"
      />
    </svg>
  );
};

export default Instagram;
