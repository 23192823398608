import RealisationDetails from "../../pages/RealisationDetails";

const Graine = () => {
  return (
    <>
      <RealisationDetails
        preview="GRAINE-Guyane-Éducation-à-l-environnement.jpg"
        siteUrl="https://graineguyane.org/"
      >
        <h2>Le GRAINE Guyane</h2>

        <p>
          Ma mission : Dans le cadre d’un partenariat avec AMAZONE STUDIO,
          développement et intégration du template WordPress.
        </p>

        <ul>
          <li>Designer : Jérémie LAHANQUE</li>
          <li>Studio : Côté Cube</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Graine;
