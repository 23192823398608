import { HashLink } from "react-router-hash-link";

const MainMenu = () => {
  return (
    <ul className="main-menu">
      <li>
        <HashLink className="main-menu-link" to="/#realisation">
          Réalisations
        </HashLink>
      </li>
      <li>
        <HashLink className="main-menu-link" to="/#service">
          Services
        </HashLink>
      </li>
      <li>
        <HashLink className="main-menu-link" to="/#about">
          Qui suis-je
        </HashLink>
      </li>
      <li>
        <HashLink className="main-menu-link" to="/#blog">
          Blog
        </HashLink>
      </li>
    </ul>
  );
};

export default MainMenu;
