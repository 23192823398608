import ServiceItem from "./ServiceItem";

const Service = () => {
  return (
    <section className="service" id="service">
      <div className="container">
        <div className="service__wrapper">
          <ServiceItem>
            <h3>Widget pour Elementor</h3>

            <div className="description">
              <p>Ne limitez plus vos designs aux widgets existants.</p>
              <p>
                Je fais des widgets personnalisés qui correspondent à vos
                besoins.
              </p>
            </div>

            <div className="tech-stack">
              <h4>Technologies :</h4>

              <ul>
                <li>Elementor Theme Builder</li>
                <li>HTML / CSS / JavaScript</li>
              </ul>
            </div>
          </ServiceItem>

          <ServiceItem readMoreUrl="/service/theme-wordpress">
            <h3>Thème WordPress</h3>

            <div className="description">
              <p>Donner à votre site un look unique et professionel</p>
              <p>
                Je convertis vos designs en un thème WordPress optimisé pour le
                SEO
              </p>
            </div>

            <div className="tech-stack">
              <h4>Technologies :</h4>

              <ul>
                <li>WordPress</li>
                <li>HTML / CSS / JavaScript</li>
              </ul>
            </div>
          </ServiceItem>

          <ServiceItem>
            <h3>Application web</h3>

            <div className="description">
              <p>
                Améliorer l’expérience utilisateur et fidéliser vos clients.
              </p>
              <p>Je construis des aplications web à partir de vos designs.</p>
            </div>

            <div className="tech-stack">
              <h4>Technologies :</h4>

              <ul>
                <li>React</li>
                <li>SASS</li>
              </ul>
            </div>
          </ServiceItem>
        </div>
      </div>
    </section>
  );
};

export default Service;
