const YouCanBookMe = ({ children }) => {
  return (
    <a
      href="https://alixfdm.youcanbookme.com/"
      className="you-can-book-me btn-orange"
    >
      {children}
    </a>
  );
};

export default YouCanBookMe;
