export const transition = { duration: 1.4, ease: [0.6, 0.01, -0.05, 0.9] };
export const transition1 = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };
export const transition2 = { duration: 0.8, ease: [0.6, 0.01, -0.05, 0.9] };

export const MyVariants = {
  myWhileHover: {
    scale: 1.025,
    transition: transition1,
  },

  myExit: {
    opacity: 0,
    transition: transition2,
  },

  myExit2: {
    opacity: 0,
    scale: 1.025,
    transition: transition2,
  },

  myInitial: {
    opacity: 0,
  },

  myAnimate: {
    opacity: 1,
    transition: transition2,
  },
};
