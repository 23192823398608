import YouCanBookMe from "./YouCanBookMe";

const CallToAction = () => {
  return (
    <section className="cta">
      <div className="container">
        <div className="box">
          <h2>Travaillons ensemble</h2>
          <ul>
            <li>Vous avez un projet ?</li>
            <li>Une mission ?</li>
            <li>Des questions ?</li>
          </ul>

          <YouCanBookMe>Prendre rendez-vous</YouCanBookMe>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;
