import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import Emak from "./components/RealisationDetails/Emak";
import Crpv from "./components/RealisationDetails/CRPV";
import Graine from "./components/RealisationDetails/Graine";
import Guyasis from "./components/RealisationDetails/Guyasis";
import Kourou from "./components/RealisationDetails/Kourou";
import Pnrg from "./components/RealisationDetails/Pnrg";
import { Redirect } from "react-router";
import WPTheme from "./pages/WPTheme";
import LegalMention from "./pages/LegalMention";

export const Routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/mentions-legales/",
    component: LegalMention,
  },
  {
    path: "/service",
    exact: true,
    render: () => {
      return <Redirect to="/" />;
    },
  },
  {
    path: "/service/theme-wordpress",
    component: WPTheme,
  },
  {
    path: "/realisation",
    exact: true,
    render: () => {
      return <Redirect to="/" />;
    },
  },
  {
    path: "/realisation/emak",
    component: Emak,
  },
  {
    path: "/realisation/crpv",
    component: Crpv,
  },
  {
    path: "/realisation/graine",
    component: Graine,
  },
  {
    path: "/realisation/guyasis",
    component: Guyasis,
  },
  {
    path: "/realisation/kourou",
    component: Kourou,
  },
  {
    path: "/realisation/pnrg",
    component: Pnrg,
  },
  {
    path: "*",
    component: NotFound,
  },
];
