import { motion, useTransform, useViewportScroll } from "framer-motion";
import { MyVariants } from "./MyVariants";
import RealisationItem from "./RealisationItem";

const RealisationGalery = () => {
  const { scrollYProgress } = useViewportScroll();

  const innerWidth = {
    1440: {
      y: [0, 0.2],
      t: [250, 0],
    },

    720: {
      y: [0, 0.15],
      t: [75, 0],
    },
  };

  const w = window.innerWidth > 720 ? innerWidth[1440] : innerWidth[720];
  const translate = useTransform(scrollYProgress, w.y, w.t);

  return (
    <motion.div
      variants={MyVariants}
      initial="myInitial"
      animate="myAnimate"
      style={{ y: translate }}
      className="realisation-galery"
    >
      <div className="realisation__wrapper">
        <RealisationItem
          src="/img/realisation/logo/logo-guyasis.png"
          detailsLink="/realisation/guyasis"
        />
        <RealisationItem
          src="/img/realisation/logo/logo-ville-kourou.png"
          detailsLink="/realisation/kourou"
        />
        <RealisationItem
          src="/img/realisation/logo/logo-graine-guyane.png"
          detailsLink="/realisation/graine"
        />
        <RealisationItem
          src="/img/realisation/logo/logo-crpv.png"
          detailsLink="/realisation/crpv"
        />
        <RealisationItem
          src="/img/realisation/logo/logo-pnrg.png"
          name="pnrg"
          detailsLink="/realisation/pnrg"
        />
        <RealisationItem
          src="/img/realisation/logo/logo-emak.png"
          detailsLink="/realisation/emak"
        />
      </div>
    </motion.div>
  );
};

export default RealisationGalery;
