import Logo from "./Logo";
import MainMenu from "./MainMenu";
import BurgerMenu from "./BurgerMenu";
import { useCallback, useEffect, useState } from "react";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    // to prevent the menu re re render change

    setIsMenuOpen(!isMenuOpen);
    const header = document.querySelector("header");
    header.classList.toggle("mobile");
  }, [isMenuOpen]);

  useEffect(() => {
    if (isMenuOpen) {
      const mainMenuLinks = document.querySelectorAll(".main-menu-link");

      mainMenuLinks.forEach((link) => {
        link.addEventListener("click", toggleMenu);
      });
    }
  }, [isMenuOpen, toggleMenu]);

  const btnContacter = (
    <a href="mailto:bonjour@alixfdm.fr" className="btn-orange contacter">
      Contacter
    </a>
  );
  return (
    <header className="header">
      <div className="container">
        <nav>
          <Logo />

          {isMenuOpen && (
            <div className="menu__wrapper">
              <div className="grid-content__wrapper">
                <MainMenu onClick={toggleMenu} />
                {btnContacter}
              </div>
            </div>
          )}

          {!isMenuOpen && <MainMenu mobile={isMenuOpen} />}
          {!isMenuOpen && btnContacter}

          <BurgerMenu onClick={toggleMenu} />
        </nav>
      </div>
    </header>
  );
};

export default Header;
