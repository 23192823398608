import { motion, useTransform, useViewportScroll } from "framer-motion";
import BlogItem from "./BlogItem";
import { useFetchData } from "./hooks/useFetchData";

const Blog = () => {
  const { scrollYProgress } = useViewportScroll();

  const innerWidth = {
    1440: {
      y: [0, 0.65],
      t: [1200, 0],
    },
    720: {
      y: [0, 0.7],
      t: [200, 0],
    },
  };

  const w = window.innerWidth > 720 ? innerWidth[1440] : innerWidth[720];
  const translate = useTransform(scrollYProgress, w.y, w.t);

  const {
    error,
    isPending,
    data: posts,
  } = useFetchData(
    "https://blog.snippetsnack.fr/wp-json/wp/v2/posts?per_page=3&filter[orderby]=date&order=desc"
  );

  return (
    <section className="blog" id="blog">
      <motion.div className="container" style={{ y: translate }}>
        <h2>Blog</h2>

        <h3>Des snippets inspirants pour vos designs</h3>

        <div className="blog__wrapper">
          {isPending && <div>Loading data...</div>}
          {error && <div>{error}</div>}
          {posts && posts.map((item) => <BlogItem key={item.id} item={item} />)}
        </div>

        <a href="https://blog.snippetsnack.fr" className="more-blog">
          Voir d'autre articles
        </a>
      </motion.div>
    </section>
  );
};

export default Blog;
