import { Link } from "react-router-dom";
import Logo from "./Logo";
import SocialLink from "./SocialLink";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <Logo url="#" />
          {/* <span className="quote"> */}
          <p>
            <q>
              Un voyage de mille lieues à toujours commencé par un premier pas
            </q>
            - Lao-Tseu
          </p>
          {/* </span> */}

          <SocialLink />

          <div className="copy">
            <span>&copy; Alix François Dit Maréchaux</span>
            <span> - </span>
            <Link to="/mentions-legales">Mentions légales</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
