import Envelop from "./assets/Envelop";
import GitHub from "./assets/Github";
import Instagram from "./assets/Instagram";
import LinkedIn from "./assets/LinkedIn";
import MailToAFDM from "./MailToAFDM";

const SocialLink = () => {
  return (
    <nav className="social-link">
      <div className="social-link__wrapper">
        <MailToAFDM>
          <Envelop />
        </MailToAFDM>
        <a className="social-link" href="https://instagram.com/alix.fdm/">
          <Instagram />
        </a>
        <a className="social-link" href="https://github.com/afdm">
          <GitHub />
        </a>
        <a
          className="social-link"
          href="https://linkedin.com/in/alix-fdm-frontend-developer/"
        >
          <LinkedIn />
        </a>
      </div>
    </nav>
  );
};

export default SocialLink;
