import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import Button from "../components/Button";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { MyVariants, transition } from "../components/MyVariants";
import RealisationGalery from "../components/RealisationGalery";

const RealisationDetails = ({ preview, siteUrl, children }) => {
  const [canScroll, setCanScroll] = useState(false);

  const toggleNoScroll = () => {
    document.querySelector("html").classList.toggle("no-scroll");
    window.scroll(0, 0);
  };

  const imgSrc = process.env.PUBLIC_URL + "/img/realisation/preview/";

  useEffect(() => {
    if (canScroll) {
      toggleNoScroll();
    } else {
      toggleNoScroll();
    }
  }, [canScroll]);

  return (
    <>
      <Header />
      <motion.div
        variants={MyVariants}
        initial="myInitial"
        animate="myAnimate"
        exit="myExit"
        className="realisation-details container"
      >
        <div className="realisation-details__wrapper">
          <main className="realisation-details__content">
            <div>{children}</div>

            <div className="img_wrapper">
              <motion.img
                onAnimationComplete={() => {
                  setCanScroll(true);
                }}
                initial={{ scale: 1.25 }}
                animate={{ scale: 1 }}
                transition={transition}
                src={imgSrc + preview}
                alt=""
              />
            </div>

            <div className="button-group">
              <Button url="/">Retour vers la page d'accueil</Button>
              <a href={siteUrl} target="_blank" rel="noreferrer">
                Voir le site
              </a>
            </div>
          </main>

          <motion.h2 initial="myInitial" animate="myAnimate">
            Autres réalisations
          </motion.h2>

          <RealisationGalery />
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default RealisationDetails;
