import { motion } from "framer-motion";
import { HashLink } from "react-router-hash-link";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero__wrapper">
          <motion.div
            animate={{ y: "-25%" }}
            transition={{ duration: 0.8, ease: [0.43, 0.13, 0.23, 0.96] }}
            className="v-alignment"
          >
            <p>Freelance, Designer, PME</p>
            <h1>Je convertis vos designs en un site
              ou une application</h1>

            <ul>
              <li>Responsive</li>
              <li>Accessible</li>
              <li>Riche en fonctionnalités</li>
            </ul>

            <HashLink className="filled btn-orange" to="/#service">
              Voir les services
            </HashLink>
          </motion.div>
        </div>
      </div>
      <img src={process.env.PUBLIC_URL + "/img/hero_deco.png"} alt="" />
    </section>
  );
};

export default Hero;
