import RealisationDetails from "../../pages/RealisationDetails";

const Guyasis = () => {
  return (
    <>
      <RealisationDetails
        preview="GCS-Guyasis-Groupement-de-Coopération-Sanitaire-Guyane-Système-d’Information-de-Santé.jpg"
        siteUrl="https://gcsguyasis.fr/"
      >
        <h2>GCS Guyasis</h2>

        <p>
          Ma mission : Dans le cadre d’un partenariat avec AMAZONE STUDIO,
          développement et intégration du template WordPress.
        </p>

        <ul>
          <li>Designer : Jérémie LAHANQUE</li>
          <li>Studio : Côté Cube</li>
        </ul>
      </RealisationDetails>
    </>
  );
};

export default Guyasis;
