import { motion, useTransform, useViewportScroll } from "framer-motion";
import ProjetItem from "./ProjetItem";

const Projet = () => {
  const { scrollYProgress } = useViewportScroll();

  const innerWidth = {
    1440: {
      y: [0, 0.76],
      t: [1500, 0],
    },
    720: {
      y: [0, 0.9],
      t: [200, 0],
    },
  };

  const w = window.innerWidth > 720 ? innerWidth[1440] : innerWidth[720];
  const translate = useTransform(scrollYProgress, w.y, w.t);

  return (
    <section className="projet">
      <motion.div className="container" style={{ y: translate }}>
        <h2>Mes autres projets</h2>
        <h3>Les autres projets dans lequels je m'investis</h3>

        <div className="projet__wrapper">
          <ProjetItem
            item={{ url: "https://pictonia.co", urlName: "pictonia.co" }}
          >
            <h3>Pictonia</h3>
            <p>
              Pictonia est un projet graphique et numérique pour et avec la
              diversité guyanaise.
            </p>

            <p>
              Il s’agit de construire ensemble une banque de pictogrammes qui
              puissent être à la disposition de tous afin de mieux représenter
              la diversité culturelle et naturelle de la Guyane.
            </p>
          </ProjetItem>

          <ProjetItem
            item={{
              url: "https://snippetsnack.fr",
              urlName: "Snippet Snack",
            }}
          >
            <h3>Snippet Snack</h3>
            <p>
              Snippet Snack est un blog qui a pour but de regrouper, expliquer
              et partager, une large librairie de snippets utilisés pour faire
              des micro animations.
            </p>
          </ProjetItem>
        </div>
      </motion.div>
    </section>
  );
};

export default Projet;
